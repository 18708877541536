fieldset {
    border-color: #2e344e !important;
}

.MuiOutlinedInput-input {
    opacity: 0.3;
    z-index: 2222222222222222222222222222222222;
}

.MuiOutlinedInput-root {
    border-radius: 10px !important;
}

