.overlay {
    width: 0%;
    height: 100%;
    background-color: rgba(51, 42, 187, 0.3);
    position: absolute;
}

.listItem:hover .overlay {
    width: 100%;
    transition: .9s;
}

.listItem:hover .listItemText {
    color: #037fff !important;
    transition: .6s;
}

.listItem:hover .makeStyles-drawerIcon-10 {
    color: #037fff !important;
    transform: rotate(1080deg);
    transition: transform 1.5s;
    font-size: 33px;
    z-index: 1111111111111111111111111111;
}

.line1 {
    width: 1px;
    min-height: 100%;
    background-color: rgba(46, 52, 78, .3);
    position: absolute;
    left: 20%;
    z-index: -1;
}

.line2 {
    width: 1px;
    min-height: 100%;
    background-color: rgba(46, 52, 78, .3);
    position: absolute;
    left: 40%;
    z-index: -1;
}

.line3 {
    width: 1px;
    min-height: 100%;
    background-color: rgba(46, 52, 78, .3);
    position: absolute;
    left: 60%;
    z-index: -1;
}

.line4 {
    width: 1px;
    min-height: 100%;
    background-color: rgba(46, 52, 78, .3);
    position: absolute;
    left: 80%;
    z-index: -1;
}

/****************استایل دهی با خودم*************/

main.makeStyles-content-9 {
    overflow: hidden;
}

/* .MuiTab-wrapper {
    width: 100px;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.MuiTabs-flexContainer {
    display: flex;
    justify-content: flex-start;
} */

/****************استایل دهی با خودم*************/