/* این فایل وظیفه تعریف و بارگذاری فونت های «فونت ایران» را برعهده دارد */
/* پیشنهاد می شود از روش قبل استفاده کنید */
 
@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/IRANSansWeb(FaNum)_Bold.eot');
    src: url('../fonts/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
         url('../fonts/IRANSansWeb(FaNum).woff2') format('woff2'),  /* Chrome36+, Opera24+*/
         url('../fonts/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
         url('../fonts/IRANSansWeb(FaNum).ttf') format('truetype');
}
